import React from 'react'
//css
import CTA from '../CTA/CTA'
import './Guarantee.css'

import { motion } from 'framer-motion'
import { StaticImage } from 'gatsby-plugin-image'

//images
const moneyBackGuarantee = '../../images/static-imgs/money-back-guarantee.webp'
const slideUpVariant={
  offscreen: {
    opacity: 0,
    y: 75,
    transition: {
      duration: 1,
      ease: 'easeInOut',
    }
    
  },

  onscreen:{
    opacity: 1,
    y:0,
    transition: {
      duration: .5,
      ease: 'easeInOut',
    }
  }

}
export default function Guarantee({buttonVariant, handleCTA, dontShowCTA }) {
  return (
    <motion.div variants = {slideUpVariant} className="guarantee-main">
      <div className="guarantee-header">Meet the Hangover-Free Guarantee</div>
      <div className="guarantee-text">
        Let's be honest, most hangover products promise the same thing. But
        rarely do brands actually give you your money back if it doesn't work
        for you. That's our <strong>Hangover Free Guarantee.</strong>
      </div>
      <div className="guarantee-icon-section">
        <div className="guarantee-icon-text">30-Day Guarantee</div>
        <StaticImage className="guarantee-icon" src={moneyBackGuarantee} alt = 'Bender money back guarantee' />
      </div>
      
      {!dontShowCTA &&  <div style = {{paddingBottom:'20px', display:'flex', flexDirection:'column', alignItems:'center'}}>
        <CTA handleCTA={handleCTA} variant={buttonVariant} />
      </div>
      }
     
    </motion.div>
  )
}
