import React from 'react';
import './Countdown.css'
import Countdown from 'react-countdown';
import {useState, useEffect} from 'react'

export default function CD(){
    const [countdown, setCountdown] = useState(null)
  
    useEffect(()=>{
        activateCountdown()
    },[])
    

    async function activateCountdown(){
        //check ls for existing countdown
        if(localStorage.getItem('countdown') === null){
            let now = new Date()
            console.log(now)
            //1 day = 86400 secs * 1000 to convert to ms
            let ed = new Date(new Date().getTime() + (24 * 60 * 60 * 1000 *3)).getTime();
            console.log(ed)
            setCountdown(<Countdown date={parseInt(ed)} renderer = {renderer}/>)
            localStorage.setItem('countdown', ed)
        }else{
            let ed = await localStorage.getItem('countdown')
            setCountdown(<Countdown date={parseInt(ed)} renderer = {renderer}/>)
        }
    }


    const renderer = ({days, hours, minutes, seconds, completed}) => {
        return ( 
            <div className = 'countdown-remaining'>
                <div className='cd-col'><span className='cd-time'>{days}</span><span className='cd-time-label'>Days</span></div>
                <div className = 'cd-sep'>:</div>
                <div className='cd-col'><span className='cd-time'>{hours}</span><span className='cd-time-label'>Hours</span></div>
                <div className = 'cd-sep'>:</div>
                <div className='cd-col'><span className='cd-time'>{minutes}</span><span className='cd-time-label'>Minutes</span></div>
                <div className = 'cd-sep'>:</div>
                <div className='cd-col'><span className='cd-time'>{seconds}</span><span className='cd-time-label'>Seconds</span></div>
            </div>
        )
    }

    return(
        <>
         {countdown}
        </>
        

    )

}