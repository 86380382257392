import React from 'react';
import Countdown from '../Countdown/Countdown';

export default function CTA({handleCTA, variant}){
    if(variant === 'tiktok offer'){
        return (
        <>
        <div className="buy-button" style={{textDecoration: 'none'}} onClick = {()=>handleCTA('Price Row','Claim your 15% off')}>
            Claim your 15% off
        </div>
         <div className = 'tiktok-offer-countdown'>
            <Countdown />
         </div>
        </>
        )
    }else{

        return(
            
            <div className="buy-button" style={{textDecoration: 'none'}} onClick = {()=>handleCTA('Price Row','SHOP NOW')}>
            SHOP NOW
            </div>
           
            


        )
    }



}